<template>
  <section
    class="flex w-full"
    :class="[
      data?.rightSide ? 'lg:flex-row-reverse' : 'lg:flex-row',
      data?.bottomMobile ? 'max-lg:flex-col-reverse' : 'max-lg:flex-col',
    ]"
    :style="{
      backgroundColor: lgAndUp
        ? data?.imageBackgroundColor?.hexa || '#F4EEE8'
        : data?.imageBackgroundColorMobile?.hexa ||
          data?.imageBackgroundColor?.hexa ||
          '#F4EEE8',
    }"
  >

    <div
      class="w-full lg:w-1/2 flex-1"
    >
      <video
        v-if="data?.file?.media_type?.includes(assetsTypes.video)"
        class="h-full w-full object-cover"
        :src="
          lgAndDown ? data?.fileMobile?.url || data?.file?.url : data?.file?.url
        "
        autoplay
        loop
        muted
        playsinline
      />
      <UiLazyImg
        v-else
        class="h-full w-full"
        :src="data?.file?.url || ''"
        :src-mobile="data?.fileMobile?.url || ''"
        :img-classes="'max-lg:max-h-[560px]'"
        :alt="
          data?.file?.alt ||
          removeHTMLTagFromString(data?.title || '') ||
          'Image'
        "
        cover
      />
    </div>

    <ul class="grid gap-y-4 gap-x-[5px] grid-cols-[repeat(2,minmax(0,1fr))] flex-1">
      <li v-for="(item, idx) in mappedData" :key="item.id" class="relative">
        <SharedEntityItemGridCard
          v-if="!data.productView"
          :data="data"
          :item="item as EntityData & Pick<BlockEntity, 'active' | 'link'>"
        />
        <CommonProductCardPlp
          v-else
          :product="item as CatalogData"
          :product-index="idx"
          :plp-type="plpTypes.default"
          :statistic-item-list-name="pageData?.currentTranslation?.title"
          :image-brightness-filter="data.image_brightness_filter"
          :image-background-color="data.imageBackgroundColor"
          :card-content-position="data?.card_content_position"
        />
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import {
  BlockEntitiesContent,
  BlockEntity,
  CatalogData,
  EntityData,
  PageData,
  assetsTypes,
} from "ecom-types";
import { plpTypes } from "~/configs";

const props = defineProps<{
  mappedData:
    | CatalogData[]
    | (EntityData[] & Pick<BlockEntity, "active" | "link">)
    | [];
  data: BlockEntitiesContent;
  maxItemsToShow: number;
  spaceBetween: any;
  pageData?: PageData | null;
}>();

const localePath = useLocalePathPolyfill();
const { lgAndUp, lgAndDown } = useAppBreakpoints();
</script>
