<template>
  <BlocksEntityBlockGridView
    v-if="data.gridView"
    :mapped-data="mapedData"
    :data="data"
    :max-items-to-show="maxItemsToShow"
    :space-between="spaceBetween"
    :page-data="pageData"
  />
  <BlocksEntityBlockGrid2ColView
    v-else-if="data.grid2ColView"
    :mapped-data="mapedData"
    :data="data"
    :max-items-to-show="maxItemsToShowForGrid2Col"
    :space-between="spaceBetween"
    :page-data="pageData"
  />
  <section
    v-else
    :style="{
      backgroundColor: data.imageBackgroundColor?.hexa || 'transparent',
    }"
  >
    <div class="pt-7.5 pb-10 lg:py-18">
      <div class="container mb-7.5 lg:mb-5">
        <div class="text-center space-y-2.5">
          <div
            v-if="data.subTitle"
            class="text-sm lg:text-base font-medium"
            v-html="data.subTitle"
          />
          <h2
            v-if="data?.title"
            class="ui-h3 relative w-full text-neutral-black-800"
            :style="
              { textAlign: data?.title_position || 'center' } as StyleValue
            "
          >
            <span
              class="block whitespace-pre-wrap capitalize"
              v-html="data.title"
            />
          </h2>
        </div>

        <div
          v-if="!hideArrows && mapedData?.length && swiperInited"
          class="text-right max-lg:hidden"
        >
          <button
            ref="prevEl"
            :aria-label="$t('previousSlide')"
            class="mr-5 inline-flex h-[46px] w-[46px] items-center justify-center rounded-full border border-solid text-primary border-primary transition-all disabled:opacity-50"
          >
            <UiIcon name="arrow_left" class="size-6" />
          </button>
          <button
            ref="nextEl"
            :aria-label="$t('nextSlide')"
            class="inline-flex h-[46px] w-[46px] items-center justify-center rounded-full border border-solid text-primary border-primary transition-all disabled:opacity-50"
          >
            <UiIcon name="arrow_right" class="size-6" />
          </button>
        </div>
        <div v-else class="mb-14 max-lg:hidden"></div>
      </div>
      <div
        v-if="data.tabsView && data.productView && tabsData"
        class="container flex"
        :class="[
          data?.title_position === 'center'
            ? 'justify-center'
            : 'justify-start',
        ]"
      >
        <ul class="mb-10 mr-auto inline-flex gap-x-5 overflow-x-auto">
          <li
            v-for="(tab, idx) in tabsData"
            :key="tab.label"
            class="flex-shrink-0 whitespace-nowrap text-sm capitalize text-gray-disabled"
            :class="[
              activeTab === idx ? 'cursor-default underline' : 'cursor-pointer',
            ]"
            @click="activeTab = idx"
          >
            {{ tab.label }}
          </li>
        </ul>
      </div>

      <UiScrollableSlider
        v-if="!isMobileGridRowsSlider"
        :items-list="mapedData"
        :slides-count="mobileSlidesCount"
        :gap-x="spaceBetween.default"
        class="px-0 lg:!hidden"
        :class="{
          container: !data.productView,
          'mobile-product-slider': data.productView,
        }"
      >
        <template
          #card="{
            item,
            idx,
          }: {
            item:
              | (EntityData & Pick<BlockEntity, 'active' | 'link'>)
              | CatalogData;
            idx: number;
          }"
        >
          <SharedEntityItemCard
            v-if="!data.productView"
            :data="data"
            :item="item as EntityData & Pick<BlockEntity, 'active' | 'link'>"
          />
          <CommonProductCardPlp
            v-else
            :product="item as CatalogData"
            :product-index="idx"
            :plp-type="plpTypes.default"
            :statistic-item-list-name="pageData?.currentTranslation?.title"
            :image-brightness-filter="data.image_brightness_filter"
            :image-background-color="data.imageBackgroundColorMobile"
            :card-content-position="data?.card_content_position"
          />
        </template>
      </UiScrollableSlider>

      <div
        v-if="mapedData.length"
        :class="{
          container: !data.productView,
          'max-lg:hidden': !isMobileGridRowsSlider,
        }"
      >
        <Swiper
          class="w-full"
          :slides-per-view="mobileSlidesCount"
          :space-between="spaceBetween.default"
          :loop="false"
          :centered-slides="false"
          :a11y="true"
          :initial-slide="0"
          :modules="modules"
          :navigation="navigation"
          :breakpoints="{
            [screens.lg]: {
              slidesPerView: data?.slidesCount?.desktop || 4,
              centeredSlides: false,
              initialSlide: 0,
              spaceBetween: spaceBetween.lg,
              grid: {
                rows: data.slides_rows?.desktop || 1,
                fill: 'row',
              },
            },
          }"
          :grid="{
            rows: data.slides_rows?.mobile || 1,
            fill: 'row',
          }"
          @swiper="initSwiper"
          @resize="onUpdateSwiper"
        >
          <SwiperSlide
            v-for="(item, idx) in mapedData.slice(0, maxItemsToShow)"
            :key="item.id"
          >
            <SharedEntityItemCard
              v-if="!data.productView"
              :data="data"
              :item="item as EntityData & Pick<BlockEntity, 'active' | 'link'>"
            />
            <CommonProductCardPlp
              v-else
              :product="item as CatalogData"
              :product-index="idx"
              :plp-type="plpTypes.default"
              :statistic-item-list-name="pageData?.currentTranslation?.title"
              :image-brightness-filter="data.image_brightness_filter"
              :image-background-color="data.imageBackgroundColor"
              :card-content-position="data?.card_content_position"
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div
        v-if="data.buttonLabel && data.buttonUrl"
        class="container text-center mt-10 lg:mt-18"
      >
        <UiButton size="md" :to="localePath(data.buttonUrl)">
          {{ data.buttonLabel }}
        </UiButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Swiper as SwiperInstance, Grid } from "swiper";
import { NavigationOptions } from "swiper/types";
import { debounce } from "lodash-es";
import { StyleValue } from "vue";
import { hash } from "ohash";
import {
  BlockEntitiesContent,
  BlockEntitiesContentTypesE,
  BlockEntity,
  CatalogData,
  EntitiesResponse,
  EntityData,
  PageData,
} from "ecom-types";
import { screens } from "~/configs/breakpointsConfig.json";
import { plpTypes } from "~/configs";
import "swiper/css/grid";

const { $api } = useNuxtApp();

const { t } = useI18n();

const { viewItemListEvent } = useEvents();
const localePath = useLocalePathPolyfill();
const isMounted = useMounted();

const props = defineProps<{
  data: BlockEntitiesContent;
  blockName: string;
  pageData?: PageData | null;
}>();
const hideArrows = ref(false);
const activeTab = ref(0);
const entityResponse = ref<EntitiesResponse | null>(null);

const marginButtonTop = ref(0);
const prevEl = ref<HTMLElement | null>(null);
const nextEl = ref<HTMLElement | null>(null);
const swiperInited = ref(false);

const maxItemsToShow = ref(15);
const maxItemsToShowForGrid2Col = ref(4);
const maxItemsToShowForTabs = ref(999);
// const isCentered = ref(false);

const navigation = reactive({
  prevEl,
  nextEl,
  disabledClass: "opacity-20 cursor-not-allowed hover:bg-transparent",
}) as NavigationOptions;

const modules = [Navigation, Grid];

const ids = props.data.entities?.reduce(
  (acc, el) => {
    if (el.active && el.id) {
      acc.push(el.id);
    }
    return acc;
  },
  [] as Array<string | number>,
);

const mobileSlidesCount = computed(() => {
  if (props.data.slidesCount?.mobile) {
    return props.data.slidesCount.mobile;
  }
  if (props.data.productView) {
    return 2;
  }
  return 1.5;
});

const isMobileGridRowsSlider = computed(
  () => props.data.slides_rows?.mobile > 1,
);

const catalogQueryString = computed(() => {
  const entities = props.data?.entities ?? [];
  const temp = entities.reduce<number[]>((acc, current) => {
    if (current.active) {
      acc.push(current.id as number);
    }
    return acc;
  }, []);

  const params: any = {};
  if (props.data.type === BlockEntitiesContentTypesE.collections) {
    params.column = "collection_sort_order";
    params.order_by = "asc";
  }
  return objectToQuery({
    [String(props.data.type)]: temp,
    count: props.data.tabsView
      ? maxItemsToShowForTabs.value
      : props.data.grid2ColView
        ? maxItemsToShowForGrid2Col.value
        : maxItemsToShow.value,
    ...params,
  });
});

const { data: resData } = props.data.productView
  ? await useAsyncCachedData(
      hash([
        `product-view-${props.data.productView}`,
        unref(catalogQueryString),
      ]),
      () => {
        return $api.products.getCatalog(catalogQueryString);
      },
    )
  : await useAsyncCachedData(`${props?.data?.type}-${ids?.toString()}`, () => {
      return $api.entities.getEntities(
        props?.data?.type || "",
        objectToQuery({
          ids,
          count: props.data.tabsView
            ? maxItemsToShowForTabs.value
            : props.data.grid2ColView
              ? maxItemsToShowForGrid2Col.value
              : maxItemsToShow.value,
        }),
      );
    });

if (props.data.tabsView && props.data.productView) {
  const { data: entityRes } = await useAsyncCachedData(
    `tabsView-${props?.data?.type}-{${ids?.toString()}`,
    () => {
      return $api.entities.getEntities(
        props?.data?.type || "",
        objectToQuery({
          ids,
        }),
      );
    },
  );
  entityResponse.value = entityRes.value;
}

const tabsData = computed(() => {
  if (
    props.data.tabsView &&
    props.data.productView &&
    entityResponse.value &&
    resData.value
  ) {
    return entityResponse.value?.data?.reduce(
      (acc, el: EntityData) => {
        const tab = {
          id: el.id,
          label: el?.currentTranslation?.title || "",
          items:
            groupProducts((resData.value?.data as CatalogData[]) || [], el) ||
            [],
        };
        acc.push(tab);
        return acc;
      },
      [
        {
          id: 0,
          label: t("all"),
          items: (resData.value?.data as CatalogData[]) || [],
        },
      ],
    );
  }
  return null;
});

const mapedData = computed<
  CatalogData[] | (EntityData[] & Pick<BlockEntity, "active" | "link">) | []
>(() => {
  let temp:
    | CatalogData[]
    | (EntityData[] & Pick<BlockEntity, "active" | "link">)
    | [] = [];
  if (!props.data.productView) {
    temp =
      (resData.value?.data?.reduce(
        (acc, el: any) => {
          const tempEl = props.data.entities?.find((item) => item.id === el.id);
          if (tempEl && tempEl.active) {
            acc.push({
              ...el,
              ...tempEl,
            });
          }
          return acc;
        },
        [] as Array<BlockEntity & Omit<EntityData, "id" | "sort_order">>,
      ) as EntityData[] & Omit<EntityData, "id" | "sort_order">) || [];
    temp.sort((a: any, b: any) => a.sort_order - b.sort_order);
  } else if (props.data.productView && props.data.tabsView) {
    temp = (tabsData.value?.[activeTab.value]?.items as CatalogData[]) || [];
  } else {
    temp = (resData.value?.data as CatalogData[]) || [];
  }

  return temp;
});

const spaceBetween = computed(() => {
  if (props.data.productView) {
    return {
      lg: 5,
      default: 5,
    };
  } else if (props.data.gridView) {
    return {
      lg: 18,
      default: 16,
    };
  }
  return {
    lg: 16,
    default: 16,
  };
});

watchEffect(() => {
  if (isMounted.value && props.data?.productView && mapedData.value?.length) {
    viewItemListEvent(mapedData.value, {
      item_list_name: props.pageData?.currentTranslation?.title,
    });
  }
});

function initSwiper(params: typeof SwiperInstance) {
  marginButtonTop.value =
    Number(params.slides[0]?.querySelector("picture > img")?.height || 100) /
      2 -
    24;

  debounceSwiper(params);
}

const debounceSwiper = debounce((params) => {
  hideArrows.value = params?.params?.slidesPerView >= mapedData.value.length;
  // isCentered.value = params?.params.centeredSlides;
  swiperInited.value = true;
}, 200);

function onUpdateSwiper(params: any) {
  debounceSwiper(params);
}

// const itemsNumber = computed(() => mapedData.value?.length ?? 0);

function groupProducts(items: CatalogData[] | [], tab: EntityData) {
  if (!items?.length) {
    return [];
  }
  if (props.data.type === BlockEntitiesContentTypesE.materials) {
    return items.filter((el) => el.materials?.some((i) => i.id === tab.id));
  } else if (props.data.type === BlockEntitiesContentTypesE.collections) {
    return items.filter((el) => el.collections_ids?.includes(tab.id));
  } else if (props.data.type === BlockEntitiesContentTypesE.categories) {
    return items.filter((el) => el.categories_ids?.includes(tab.id));
  }
}
</script>

<style scoped>
.is-materials.is-centered .swiper-slide:not(.swiper-slide-active) {
  transform: scale(0.75);
  opacity: 0.5;
}

/* .diamond-slider-title {
  color: #0f0f0f;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.6px;
  line-height: normal;
  max-width: none;
  margin: auto;
} */
.diamond-slider {
  padding: 40px 0;
}
.diamond-slider picture {
  transition: scale 0.25s;
}
.diamond-slider picture:hover {
  scale: 1.16;
}
.diamond-slider h3 {
  font-size: 16px;
  text-align: center;
}
.diamond-slider a:hover h3 {
  @apply font-medium;
  @apply text-primary;
}
:deep(.swiper-grid) > .swiper-wrapper {
  @apply gap-y-4;
}
:deep(.mobile-product-slider) .diamondshape-slick-mobile {
  @apply scroll-pl-0;
}
:deep(.mobile-product-slider) .diamondshape-slick-mobile li {
  @apply first:ml-0 last:mr-0;
}
</style>
