<template>
  <div class="relative">
    <NuxtLink :to="item?.link || '/'" class="inline-block mb-6 lg:mb-7.5">
      <div
        class="aspect-1 lg:aspect-[308/374] cursor-pointer"
        :style="{
          filter: `brightness(${data.image_brightness_filter || 100}%)`,
        }"
      >
        <UiLazyImg
          class="h-full w-full"
          :src="
            getImageByPosition(item.medias, 'list')?.file?.url ||
            '/images/no-image.webp'
          "
          :alt="imageAlt(item)"
          img-classes="object-cover"
        />
      </div>
    </NuxtLink>

    <NuxtLink :to="item?.link || '/'">
      <h3
        class="font-red-hat-display mb-4 lg:mb-7.5 text-xl lg:text-2xl text-neutral-black-800 font-semibold"
        :style="
          { textAlign: data?.card_content_position || 'left' } as StyleValue
        "
      >
        {{ item.currentTranslation?.title }}
      </h3>
    </NuxtLink>
    <div
      v-if="
        item?.show_short_description &&
        item.currentTranslation?.short_description
      "
      class="line-clamp-1 lg:line-clamp-none lg:text-lg text-neutral-black-80"
      v-html="item.currentTranslation?.short_description"
    />
  </div>
</template>

<script lang="ts" setup>
import { StyleValue } from "vue";
import { BlockEntitiesContent, BlockEntity, EntityData } from "ecom-types";

defineProps<{
  data: BlockEntitiesContent;
  item: EntityData &
    Pick<BlockEntity, "active" | "link" | "show_short_description">;
}>();
const { t } = useI18n();

function imageAlt(item: EntityData & Pick<BlockEntity, "active" | "link">) {
  if (getImageByPosition(item.medias, "list")?.alt) {
    return getImageByPosition(item.medias, "list")?.alt;
  } else if (item.currentTranslation?.title) {
    return `${t("image")} ${item.currentTranslation?.title}`;
  } else {
    return t("image");
  }
}
</script>
