<template>
  <section
    class="pt-7.5 pb-10 lg:py-18"
    :style="{
      backgroundColor: data.imageBackgroundColor?.hexa || 'transparent',
    }"
  >
    <div class="container mb-7.5 lg:mb-14">
      <div class="text-center space-y-2.5">
        <div
          v-if="data.subTitle"
          class="text-sm lg:text-base font-medium"
          v-html="data.subTitle"
        />
        <h2
          v-if="data?.title"
          class="ui-h3 relative w-full text-neutral-black-800"
          :style="{ textAlign: data?.title_position || 'center' } as StyleValue"
        >
          <span
            class="block whitespace-pre-wrap capitalize"
            v-html="data.title"
          />
        </h2>
      </div>
    </div>

    <ul
      :style="{
        '--grid-view-columns-count-mobile': data.slidesCount?.mobile || 2,
        '--grid-view-columns-count-desktop': data.slidesCount?.desktop || 4,
      }"
      class="grid gap-y-5 grid-cols-[repeat(var(--grid-view-columns-count-mobile,2),minmax(0,1fr))]
        lg:grid-cols-[repeat(var(--grid-view-columns-count-desktop,4),minmax(0,1fr))]"
      :class="{
        'container gap-x-4': !data.productView,
        'gap-x-[5px]': data.productView,
      }"
    >
      <li v-for="(item, idx) in mappedData" :key="item.id" class="relative">
        <SharedEntityItemGridCard
          v-if="!data.productView"
          :data="data"
          :item="item as EntityData & Pick<BlockEntity, 'active' | 'link'>"
        />
        <CommonProductCardPlp
          v-else
          :product="item as CatalogData"
          :product-index="idx"
          :plp-type="plpTypes.default"
          :statistic-item-list-name="pageData?.currentTranslation?.title"
          :image-brightness-filter="data.image_brightness_filter"
          :image-background-color="data.imageBackgroundColor"
          :card-content-position="data?.card_content_position"
        />
      </li>
    </ul>

    <div
      v-if="data.buttonLabel && data.buttonUrl"
      class="container text-center mt-10 lg:mt-18"
    >
      <UiButton size="md" :to="localePath(data.buttonUrl)">
        {{ data.buttonLabel }}
      </UiButton>
    </div>
  </section>
</template>

<script setup lang="ts">
import { StyleValue } from "vue";
import {
  BlockEntitiesContent,
  BlockEntity,
  CatalogData,
  EntityData,
  PageData,
} from "ecom-types";
import { plpTypes } from "~/configs";

const props = defineProps<{
  mappedData:
    | CatalogData[]
    | (EntityData[] & Pick<BlockEntity, "active" | "link">)
    | [];
  data: BlockEntitiesContent;
  maxItemsToShow: number;
  spaceBetween: any;
  pageData?: PageData | null;
}>();

const localePath = useLocalePathPolyfill();
</script>
